export default {
    "name": "Vehicle",
    "kind": "HoudiniQuery",
    "hash": "21bc233067e6252a67abb1abcad3c5d1e44df608a145937891bb888dd3f354f7",

    "raw": `query Vehicle($vehicleId: Int!) {
  vehicle(id: $vehicleId) {
    topImage {
      url
      id
    }
    attachmentCount
    bodyStyle
    category
    claimNumber
    componentCount
    condition
    dateEntered
    description
    dismantled
    externalColor
    externalColorCode
    make
    mileage
    model {
      name
      id
    }
    receivedDate
    stockNumber
    storeId
    vin
    internalColor
    internalColorCode
    location
    notes
    status
    userStatus
    lastChanged
    titleNumber
    titleStatus
    teardownDate
    titleType
    titleReceived
    purchasedFrom
    sellerContact
    sellerPhone
    sellerInfo
    purchaseDate
    titleState
    year
    id
  }
}
`,

    "rootType": "Query",

    "selection": {
        "fields": {
            "vehicle": {
                "type": "Vehicle",
                "keyRaw": "vehicle(id: $vehicleId)",
                "nullable": true,

                "selection": {
                    "fields": {
                        "topImage": {
                            "type": "File",
                            "keyRaw": "topImage",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "url": {
                                        "type": "String",
                                        "keyRaw": "url",
                                        "visible": true
                                    },

                                    "id": {
                                        "type": "Int",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "attachmentCount": {
                            "type": "Int",
                            "keyRaw": "attachmentCount",
                            "visible": true
                        },

                        "bodyStyle": {
                            "type": "String",
                            "keyRaw": "bodyStyle",
                            "nullable": true,
                            "visible": true
                        },

                        "category": {
                            "type": "String",
                            "keyRaw": "category",
                            "nullable": true,
                            "visible": true
                        },

                        "claimNumber": {
                            "type": "String",
                            "keyRaw": "claimNumber",
                            "nullable": true,
                            "visible": true
                        },

                        "componentCount": {
                            "type": "Int",
                            "keyRaw": "componentCount",
                            "visible": true
                        },

                        "condition": {
                            "type": "String",
                            "keyRaw": "condition",
                            "visible": true
                        },

                        "dateEntered": {
                            "type": "DateTime",
                            "keyRaw": "dateEntered",
                            "nullable": true,
                            "visible": true
                        },

                        "description": {
                            "type": "String",
                            "keyRaw": "description",
                            "nullable": true,
                            "visible": true
                        },

                        "dismantled": {
                            "type": "Boolean",
                            "keyRaw": "dismantled",
                            "visible": true
                        },

                        "externalColor": {
                            "type": "String",
                            "keyRaw": "externalColor",
                            "nullable": true,
                            "visible": true
                        },

                        "externalColorCode": {
                            "type": "String",
                            "keyRaw": "externalColorCode",
                            "nullable": true,
                            "visible": true
                        },

                        "make": {
                            "type": "String",
                            "keyRaw": "make",
                            "visible": true
                        },

                        "mileage": {
                            "type": "UInt",
                            "keyRaw": "mileage",
                            "nullable": true,
                            "visible": true
                        },

                        "model": {
                            "type": "Model",
                            "keyRaw": "model",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "id": {
                                        "type": "Int",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "receivedDate": {
                            "type": "Date",
                            "keyRaw": "receivedDate",
                            "nullable": true,
                            "visible": true
                        },

                        "stockNumber": {
                            "type": "String",
                            "keyRaw": "stockNumber",
                            "visible": true
                        },

                        "storeId": {
                            "type": "UInt",
                            "keyRaw": "storeId",
                            "visible": true
                        },

                        "vin": {
                            "type": "String",
                            "keyRaw": "vin",
                            "nullable": true,
                            "visible": true
                        },

                        "internalColor": {
                            "type": "String",
                            "keyRaw": "internalColor",
                            "nullable": true,
                            "visible": true
                        },

                        "internalColorCode": {
                            "type": "String",
                            "keyRaw": "internalColorCode",
                            "nullable": true,
                            "visible": true
                        },

                        "location": {
                            "type": "String",
                            "keyRaw": "location",
                            "visible": true
                        },

                        "notes": {
                            "type": "String",
                            "keyRaw": "notes",
                            "visible": true
                        },

                        "status": {
                            "type": "String",
                            "keyRaw": "status",
                            "visible": true
                        },

                        "userStatus": {
                            "type": "String",
                            "keyRaw": "userStatus",
                            "nullable": true,
                            "visible": true
                        },

                        "lastChanged": {
                            "type": "DateTime",
                            "keyRaw": "lastChanged",
                            "nullable": true,
                            "visible": true
                        },

                        "titleNumber": {
                            "type": "String",
                            "keyRaw": "titleNumber",
                            "visible": true
                        },

                        "titleStatus": {
                            "type": "String",
                            "keyRaw": "titleStatus",
                            "visible": true
                        },

                        "teardownDate": {
                            "type": "Date",
                            "keyRaw": "teardownDate",
                            "nullable": true,
                            "visible": true
                        },

                        "titleType": {
                            "type": "String",
                            "keyRaw": "titleType",
                            "visible": true
                        },

                        "titleReceived": {
                            "type": "DateTime",
                            "keyRaw": "titleReceived",
                            "nullable": true,
                            "visible": true
                        },

                        "purchasedFrom": {
                            "type": "String",
                            "keyRaw": "purchasedFrom",
                            "visible": true
                        },

                        "sellerContact": {
                            "type": "String",
                            "keyRaw": "sellerContact",
                            "visible": true
                        },

                        "sellerPhone": {
                            "type": "String",
                            "keyRaw": "sellerPhone",
                            "visible": true
                        },

                        "sellerInfo": {
                            "type": "String",
                            "keyRaw": "sellerInfo",
                            "visible": true
                        },

                        "purchaseDate": {
                            "type": "Date",
                            "keyRaw": "purchaseDate",
                            "nullable": true,
                            "visible": true
                        },

                        "titleState": {
                            "type": "String",
                            "keyRaw": "titleState",
                            "visible": true
                        },

                        "year": {
                            "type": "UInt",
                            "keyRaw": "year",
                            "nullable": true,
                            "visible": true
                        },

                        "id": {
                            "type": "UInt",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "vehicleId": "Int"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=ce1fb3e6c2f14065d26cdd104be66c501b1f99cd305a40422ae63841bea51065";